var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"columns diagnosis-container"},[_c('div',{staticClass:"column is-full"},[_c('div',{staticClass:"card"},[_c('header',{staticClass:"card-header"},[_c('p',{staticClass:"card-header-title"},[_vm._v("Diagnosis")]),(_vm.aktivitas.extraData.canAddKomponen)?_c('router-link',{staticClass:"card-header-icon has-text-black",attrs:{"to":{
              name: 'aktivitas-diagnosis-add',
              params: {
                aktivitasId: _vm.aktivitas.id,
              },
            }}},[_c('b-icon',{attrs:{"size":"is-small","icon":"plus"}})],1):_vm._e()],1),_c('div',{staticClass:"content card-content"},[_c('div',{staticClass:"columns is-mobile"},[_c('div',{staticClass:"column"},[(_vm.diagLength)?_c('div',[_c('b-field',{attrs:{"grouped":"","group-multiline":""}},_vm._l((_vm.sortedDiagnosisList),function(aktivitasDiagnosis){return _c('aktivitas-komponen-tag',{key:aktivitasDiagnosis.id,attrs:{"aktKomp":aktivitasDiagnosis,"namedUrl":"aktivitas-diagnosis-detail"}})}),1)],1):_c('div',[_vm._v("Tidak ada data")])])])])])])]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-full"},[_c('div',{staticClass:"card"},[_c('header',{staticClass:"card-header"},[_c('p',{staticClass:"card-header-title"},[_vm._v("Pemeriksaan")]),(_vm.aktivitas.extraData.canAddKomponen)?_c('router-link',{staticClass:"card-header-icon has-text-black",attrs:{"to":{
              name: 'aktivitas-pemeriksaan-add',
              params: {
                aktivitasId: _vm.aktivitas.id,
              },
            }}},[_c('b-icon',{attrs:{"size":"is-small","icon":"plus"}})],1):_vm._e()],1),_c('div',{staticClass:"content card-content"},[_c('div',{staticClass:"columns is-mobile"},[_c('div',{staticClass:"column"},[(_vm.pemeriksaanLength)?_c('div',[_c('b-field',{attrs:{"grouped":"","group-multiline":""}},_vm._l((_vm.aktivitas.aktPemeriksaanList),function(aktivitasPemeriksaan){return _c('aktivitas-komponen-tag',{key:aktivitasPemeriksaan.id,attrs:{"aktKomp":aktivitasPemeriksaan,"namedUrl":"aktivitas-pemeriksaan-detail"}})}),1)],1):_c('div',[_vm._v("Tidak ada data")])])])])])])]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-full"},[_c('div',{staticClass:"card"},[_c('header',{staticClass:"card-header"},[_c('p',{staticClass:"card-header-title"},[_vm._v("Tatalaksana")]),(_vm.aktivitas.extraData.canAddKomponen)?_c('router-link',{staticClass:"card-header-icon has-text-black",attrs:{"to":{
              name: 'aktivitas-tatalaksana-add',
              params: {
                aktivitasId: _vm.aktivitas.id,
              },
            }}},[_c('b-icon',{attrs:{"size":"is-small","icon":"plus"}})],1):_vm._e()],1),_c('div',{staticClass:"content card-content"},[_c('div',{staticClass:"columns is-mobile"},[_c('div',{staticClass:"column"},[(_vm.tatalaksanaLength)?_c('div',[_c('b-field',{attrs:{"grouped":"","group-multiline":""}},_vm._l((_vm.aktivitas.aktTatalaksanaList),function(aktivitasTatalaksana){return _c('aktivitas-komponen-tag',{key:aktivitasTatalaksana.id,attrs:{"aktKomp":aktivitasTatalaksana,"namedUrl":"aktivitas-tatalaksana-detail"}})}),1)],1):_c('div',[_vm._v("Tidak ada data")])])])])])])]),_c('div',{staticClass:"columns keterampilan-container"},[_c('div',{staticClass:"column is-full"},[_c('div',{staticClass:"card"},[_c('header',{staticClass:"card-header"},[_c('p',{staticClass:"card-header-title"},[_vm._v("Keterampilan")]),(_vm.aktivitas.extraData.canAddKomponen)?_c('router-link',{staticClass:"card-header-icon has-text-black",attrs:{"to":{
              name: 'aktivitas-keterampilan-add',
              params: {
                aktivitasId: _vm.aktivitas.id,
              },
            }}},[_c('b-icon',{attrs:{"size":"is-small","icon":"plus"}})],1):_vm._e()],1),_c('div',{staticClass:"content card-content"},[_c('div',{staticClass:"columns is-mobile"},[_c('div',{staticClass:"column"},[(_vm.keterampilanLength)?_c('div',[_c('b-field',{attrs:{"grouped":"","group-multiline":""}},_vm._l((_vm.aktivitas.aktKeterampilanList),function(aktivitasKeterampilan){return _c('aktivitas-komponen-tag',{key:aktivitasKeterampilan.id,attrs:{"aktKomp":aktivitasKeterampilan,"keterampilan":true,"namedUrl":"aktivitas-keterampilan-detail"}})}),1)],1):_c('div',[_vm._v("Tidak ada data")])])])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }